import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const Story = props => {
  const { className, ...rest } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const storyText = <div>Wir sind ein Startup-Unternehmen, welches die digitale Außenwerbung revolutionieren will. Der Trend geht in Richtung digital, und das nicht nur online! Studien belegen, dass digitale Außenwerbung 4 mal mehr Aufmerksamkeit erregt als klassische Plakatwerbung.
                    <br/><br/>Dementsprechend möchten wir mit unseren modernen und zukunftsorientierten digitalen Werbeflächen, auch als digitaler Plakat bekannt, Unternehmen die Möglichkeit bieten in gut besuchten Locations Ihre digitale Werbung zu schalten und eine hohe Anzahl von Sichtkontakten zu erreichen.
                    <br/><br/>Gleichzeitig halten wir Ausschau nach neuen interessanten Locations und freuen uns auf neue Kooperationen.</div>

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justify="space-between"
        spacing={isMd ? 4 : 2}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="left"
          justify="flex-start"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <div>
            <SectionHeader
              title="Unsere Vision"
              subtitle={storyText}
              align="left"
              disableGutter
              subtitleProps={{
                color: 'textPrimary',
                variant: 'body1',
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          container
          justify='center'
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src={require('assets/images/dooh/altneu/Tenbi-Plakatwerbung-Digital-Klassisch.png')}
            alt="Vergleich zwischen DooH / Plakatwerbung"
          />
          <Typography variant="overline">
            Digital vs. Classic
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

Story.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Story;
