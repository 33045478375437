import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const WhoWeAre = props => {
  const { className, ...rest } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title="Wer sind wir?"
            subtitle={`Wir sind studierte und leidenschaftliche Medieninformatiker. Mit unseren langjährigen Softwareentwickler-Kenntnissen sowie Marketing Know-How sind wir stets dabei 
                      unser DooH-Netzwerk auszuweiten sowie die dazugehörige Software zur (Selbst-)Verwaltung der DooH-Werbeflächen zu erweitern.`}
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} data-aos="fade-up" style={ isXs ? {paddingTop : 40} : {} }>
          <SectionHeader
            title="Digitale Außenwerbung für alle."
            subtitle="Die DooH-Branche in dieser Form ist noch recht jung in Deutschland. Die bekannten Big Player dahingegen sind meist viel zu teuer für kleine und mittelständige Unternehmen und somit unbezahlbar. Wir wollen dies mit unseren digitalen Werbeflächen ändern und digitale Außenwerbung für alle, aber vor allem für kleine und mittelständige Unternehmen, anbieten."
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

WhoWeAre.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhoWeAre;
